﻿(function (w, d, $) {
    $(d).ready(function () {
        //Skip Nav
        $("#skip-to-content a").on("keydown", function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                var id = $(this).attr("href");
                $(w).scrollTo(id);
                $(id).focus();
                e.preventDefault();
            }
        });
        $(".right-column .news-feed .news-item-container").addClass("arrow-link-list-light").find(".news-item > a").addClass("arrow-link-light-inline");
        $(".arrow-link-list-light").find("a").addClass("arrow-link-light-inline");

        $("a.arrow-link-inline, p.arrow-link-inline > a").append('<span class="glyphicon glyphicon-caret-right-bold" aria-hidden="true"></span>');
        $("a.arrow-link-light-inline").append('<span class="glyphicon glyphicon-caret-right" aria-hidden="true"></span>');
        function arrowLink($elem, glyph) {
                var $srOnly = "";
                if ($elem.children().hasClass("sr-only")) {
                    $srOnly = $elem.children(".sr-only").remove();
                }
                var words = $elem.text().trim().split(' '),
                    lastWord = words.pop(),
                    endLength = lastWord.length,
                    index = $elem.html().lastIndexOf(lastWord),
                    htmlOriginal = $elem.html().split(glyph)[0]; 
                var html = htmlOriginal.substring(0, index - 1) + htmlOriginal.substring(index + endLength) + ' <span class="text-nowrap">' + lastWord + glyph + '</span>';
                $elem.html(html).prepend($srOnly);
        }
        //Fix to add text-nowrap span around last word and glyph in arrow links. Prevent's "hanging" glyph
        $("a.arrow-link:not(.back-to-top), a.arrow-link-inline, a.arrow-link-light-inline, p.arrow-link-inline > a").each(function () {
            var glyphBold = '<span class="glyphicon glyphicon-caret-right-bold" aria-hidden="true"></span>';
            var glyphLight = '<span class="glyphicon glyphicon-caret-right" aria-hidden="true"></span>';
            //Do not apply to non-standard arrow links
            if ($(this).children().hasClass("glyphicon-chevron-right") || $(this).children().hasClass("glyphicon-caret-right-bold")) {
                arrowLink($(this), glyphBold);
            } else if ($(this).children().hasClass("glyphicon-caret-right")) {
                arrowLink($(this), glyphLight);
            }             
        });

        $(".focus-link.print-page a").click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            w.print();
        });

        $(".match-height-generic").matchHeight({
            byRow: true
        });

        $(".match-height-generic-no-row").matchHeight({
            byRow: false
        });

        $("a[data-popup], a[data-popup='']").on("click", function onPopupOpen(ev) {
            //data-popup='{"width":800,"height":500,"scroll":true,"windowname":"_somename"}' or data-popup='' => target=_blank
            var $popupVar = $(this).data("popup"),
                features = "",
                newWin;

            if ($popupVar.width > 0) {
                features += "width=" + $popupVar.width + ",";
            }

            if ($popupVar.height > 0) {
                features += "height=" + $popupVar.height + ",";
            }

            if ($popupVar.scroll === true) {
                features += "scrollbars=1,";
            } else if ($popupVar.scroll === false) {
                features += "scrollbars=0,";
            }

            if ($popupVar.windowname === null) {
                $popupVar.windowname = "_new";
            }

            if (features.length > 0) {
                newWin = w.open(ev.currentTarget.href, $popupVar.windowname, features.replace(/\,$/, ""));
                if (w.focus) {
                    newWin.focus();
                }
                ev.preventDefault();
            } else {
                newWin = w.open(ev.currentTarget.href);
                ev.preventDefault();
            }
        });

        $("a.close-window").on("click", function onPopupClose (ev) {
            w.close();
            ev.preventDefault();
        });
    });
})(window, document, jQuery);
