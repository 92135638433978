﻿(function (w, d, $) {
    $(document).ready(function () {
        $("#alertBox").each(function () {
            $(this).slideDown(500);
        });
        $("#alertBox").find(".close").click(function (e) {
            e.preventDefault();
            $(this).closest(".alert-shim").slideUp(500);
        });
    });
})(window, document, jQuery);