﻿(function (w, d, $) {
    $(document).ready(function () {        
        $('.filter-bar:not(.tab-filter-bar) .dropdown').each(function (key, dropdown) {
            var $dropdown = $(dropdown);
            //set default text to intial active li > a data-dropdown-text
            $dropdown.find('button > .selected-text').text($dropdown.find('.dropdown-menu li.active a').data('dropdown-text'));
            $dropdown.find('.dropdown-menu a').on('click', function (e) {
                e.preventDefault();
                $(this).closest("li").addClass("active").siblings("li").removeClass("active");
                $dropdown.find('button > .selected-text').text($(this).data('dropdown-text'));
            });
        });
    });
})(window, document, jQuery);
