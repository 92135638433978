﻿(function (w, d, $) {

    function reinit(id) {
        $("#" + id + " .slider").slick("unslick");
        init(id);
    }
    function init(id) {
        $("#" + id + " .slider").on('init reInit', function (event, slick, currentSlide, nextSlide) {
            $(this).css("height", "auto").css("overflow", "auto").nextAll(".slider-cover").fadeOut(500);
        }).slick({
            slide: "#" + id + " .slider>.item",
            accessibility: false,
            appendArrows: "#" + id + " .arrows",
            nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"><span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span><span class="sr-only">Next</span></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"><span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span><span class="sr-only">Previous</span></button>',
            slidesPerRow: 3,
            slidesToScroll: 3,
            slidesToShow: 3,
            infinite: false,
            responsive: [
                { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1, slidesPerRow: 1 } }
            ]
        });

        //$("#" + id + " .slider").on('init reInit', function (event, slick, currentSlide, nextSlide) {
        //    $(this).css("height", "auto").css("overflow", "visible");
        //    $(this).css("height", "auto").css("overflow", "auto").next(".slider-cover").fadeOut(500);

        //});
    }

    function paging(id) {
        var slides = $("#" + id + " .item").length;
        var slidesToShow = $("#" + id + " .slider").slick('slickGetOption', 'slidesToShow');
        var currentSlide = $("#" + id + " .slider").slick('slickCurrentSlide');
        var page = 1 + Math.ceil(currentSlide / slidesToShow);
        var total = Math.ceil(slides / slidesToShow);
        //Only show paging if there are more than one slide
        if (total > 1) {
            $("#" + id + " .paging").html('<span class="slide-count num dark-gray">' + page + ' of ' + total + '</span>').closest(".control").removeClass("hidden");
        } else {
            $("#" + id + " .paging").find('.slide-count').remove().closest(".control").addClass("hidden");
        }
    }

    $(document).ready(function () {
        $('.video-image-module-carousel').each(
            function (i) {
                var id = $(this).attr('id');
                init(id);
                paging(id);
            }
        );

        $('.video-image-module-carousel .slider').on('afterChange', function (event, slick, currentSlide) {
            paging(event.currentTarget.parentElement.id);
        });

    });
    
    //Fix for orientation changing devices
    $(w).on('resize orientationchange', function (event) {
        setTimeout(function () {
            $('.story-carousel').each(
                function (i) {
                    var id = $(this).attr('id');
                    reinit(id);
                    paging(id);
                }
            );
        }, 500);
    });

})(window, document, jQuery);
