﻿(function (w, d, $) {

    var slidesPerRow = 1;
    var slidesToScroll = 1;
    var slidesToShow = 1;
    function reinit(id) {
        $("#" + id + " .slider").slick("unslick");
        init(id);
    }
    function init(id) {
        $("#" + id + " .slider-image").on("init", function (slick) {
            $(this).css("height", "auto").css("overflow", "visible");
        })
        .slick({
            slide: "#" + id + " .slider-image > .item",
            accessibility: false,
            arrows: true,
            appendArrows: "#" + id + " .arrows",
            nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"><span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span><span class="sr-only">Next</span></button>',
            prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"><span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span><span class="sr-only">Previous</span></button>',
            slidesPerRow: 1,
            slidesToScroll: 1,
            slidesToShow: 1,
            infinite: true,
            dots: true,
            centerMode: false,
            appendDots: "#" + id + " .dots-container",
            asNavFor: $("#" + id + " .slider-caption")
        });
        $("#" + id + " .slider-caption").on("init", function (slick) {
            $(this).css("height", "auto").css("overflow", "auto").nextAll(".slider-cover").fadeOut(500);
        }).slick({
            slide: "#" + id + " .slider-caption > .item",
            accessibility: false,
            arrows: false,
            slidesPerRow: 1,
            slidesToScroll: 1,
            slidesToShow: 1,
            infinite: true,
            speed: 200,
            fade: true,
            cssEase: 'linear',
            dots: false,
            centerMode: false,
            asNavFor: $("#" + id + " .slider-image")
        });

    }

    $(document).ready(function () {
        $('.image-carousel-module').each(
            function (i) {
                var id = $(this).attr('id');
                init(id);
            }
        );
    });

})(window, document, jQuery);