﻿//.com to remove
//(function () {
//    var cx = '016672598177003296213:m091b4zx89g';
//    var gcse = document.createElement('script'); gcse.type = 'text/javascript'; gcse.async = true;
//    gcse.src = (document.location.protocol == 'https:' ? 'https:' : 'http:') +
//        '//cse.google.com/cse.js?cx=' + cx;
//    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(gcse, s);
//})();

(function (w, d, $) {
    $(document).ready(function () {
        function initSearchInput() {
            //var message = ""; "search (disabled for beta testing)";
            /* .com to remove
            $("input.gsc-input").parent().prepend('<span class="glyphicon glyphicon-search"></span>');
            $("input.gsc-input").addClass("form-control search-input font-gotham-narrow");
            $("input.gsc-input").attr("placeholder", message);
            $("input.gsc-input").attr("name", "q");*/
            //$("input.gsc-input").attr("disabled", "disabled");

            /* .com to remove
            $("input.gsc-search-button.gsc-search-button-v2").attr("alt", "search");

            $(".gsc-search-button").prepend('<button type="submit" class="search-submit gsc-search-button gsc-search-button-v2"><span class="glyphicon glyphicon-arrow-right" aria-hidden="true"><span class="sr-only">Search</span></span></button>');
            $("input.gsc-input").parents("form").attr("action", "/search/");
            $("input[name='bgresponse']").removeAttr("id"); /*duplicate Ids*/
            /*
            $('input[readonly]').focus(function () {
                this.blur();
            });
            */
            $('.input-box').each(function (i, e) {
                $(this).on('keydown cut paste', 'input[placeholder]', function () {
                    var $field = $(this);
                    //var $icon = $(this).parent().find('span.glyphicon-search');
                    var $label = $(this).parent().find('label.placeholder');
                    var initialValue = $field.val();

                    setTimeout(function () {
                        var updatedValue = $field.val();
                        if (updatedValue !== initialValue) {
                            $label.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
                            //$icon.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
                        }
                    }, 0);

                });

                //$(e).before('<label class="placeholder font-gotham-narrow" for="' + $(e).attr('id') + '">' + message + '</label>');
            });
            
        }

        var gInt = setInterval(function () {
            if ($(".input-box").length) {
                //initSearchInput();
                clearInterval(gInt);
            }
        }, 200);
        
        /*.com to remove
        $('.dropdown-large.search').on('shown.bs.dropdown', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).find('input.gsc-input').focus();
        });
        */
        
    });

    // Hide/show the <label> when text is added/removed for IE
   /*
    $(document).on('keydown.placeholder cut.placeholder paste.placeholder setValue.placeholder', 'input[placeholder]', function () {
        var $field = $(this);
        var $label = $(this).parent().find('label.placeholder');
        //var $icon = $(this).parent().find('span.glyphicon-search');

        var initialValue = $field.val();
        setTimeout(function () {
            var updatedValue = $field.val();
            if (updatedValue !== initialValue) {
                $label.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
            }
            //$icon.toggleClass('placeholder-state-hidden', updatedValue.length > 0);

        }, 0);
    });
    */ 

})(window, document, jQuery);