﻿(function (w, d, $) {
    $(document).ready(function () {
        //Adds scrollbar functionality to table when table is smaller than min width
        $(".table-container .perfect-scrollbar").each(function () {
            $(this).perfectScrollbar();
        });
        $(window).resize(function () {
            $(".table-container .perfect-scrollbar").each(function () {
                $(this).perfectScrollbar('update');
            });
        });
    });
})(window, document, jQuery);
