﻿(function (w, d, $) {
    $(document).ready(function () {
        $('input.search-input').each(function () {
            $(this).bind("input", function () {
                if ($(this).val() != "") {
                    $(this).parent().find(".clear-button").show();
                } else {
                    $(this).parent().find(".clear-button").hide();
                }
            });
        });
        $('.dropdown-menu .search-bar .clear-button').click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).parent().find(".search-input").val("");
            $(this).hide();
        });
        $('.dropdown-large.search').on('shown.bs.dropdown', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });
        $(".search-bar .search-bar-form").each(function () {
            function globalSearch($this) {
                var q = $this.find(".search-input").val(),
                    $searchButton = $this.find(".search-button"),
                    searchUrl = $searchButton.attr("href") + "#/results/query/" + w.encodeURIComponent(q) + "/pagenumber/1/resultsperpage/20/sortby/Relevance";

                if (q && q !== "") {
                    w.open(searchUrl, $searchButton.attr("target"));
                } 
            }
            var $searchForm = $(this);
            $searchForm.find(".search-button").on("click", function (e) {
                e.preventDefault();
                globalSearch($searchForm)
            });
            $searchForm.submit(function (e) {
                e.preventDefault();
                globalSearch($searchForm)
            });
        });
    });

})(window, document, jQuery);