﻿(function (w, d, $) {
    $(document).ready(function () {
        $('.tabbed-content-control .filter-bar .dropdown').each(function (key, dropdown) {
            var $dropdown = $(dropdown);
            //set default text to intial active li > a data-dropdown-text
            $dropdown.find('button > .selected-text').text($dropdown.find('.dropdown-menu li.active a').data('dropdown-text'));
            $dropdown.find('.dropdown-menu a').on('click', function (e) {   
                e.preventDefault();
                $(this).tab('show');
                $(this).closest("li").addClass("active").siblings("li").removeClass("active");
                $dropdown.find('button > .selected-text').text($(this).data('dropdown-text'));
            });
        });

        //Match heigth of tab-side-nav and current tab-pane
        $(".tabbed-content-control.tabbed-side-nav").each(function () {
            var $thisTabbedSideNav = $(this);
            $thisTabbedSideNav.find(".match-height").matchHeight({
                byRow: true
            });
            $thisTabbedSideNav.find('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                $thisTabbedSideNav.find(".match-height").matchHeight({
                    byRow: true
                });
            });
        });

        /* Example of how to activate tab using url #
        if (window.location.hash) {
            title = window.location.hash.replace("#", "");
            $('[data-toggle="tab"]').each(function (i) {
                if ($(this).text().toLowerCase() == title.toLowerCase()) {
                    $(this).tab("show");
                    $('html, body').animate({
                        scrollTop: $(this).offset().top
                    }, 2000);
                }
            });
        }*/
    });
})(window, document, jQuery);