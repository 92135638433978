﻿(function (w, d, $) {
    $(document).ready(function () {
        //Make right subsite dropdown right aligned
        $(".navbar-subsite .navbar-nav.primary").each(function () {
            var $levelOneItems = $(this).find("li.level-2");
            $levelOneItems.slice(Math.ceil($levelOneItems.length / 2)).addClass("dropdown-right-align");
        });

        //Variable for breakpoint at which main menu collapses into hamburger
        var collapseMenuWidth = 992;

        function utilityNavPosition() {
            var $utilityNav = $(".navbar-subsite #navbar-hamburger-menu .dropdown-menu"),
                offsetMargin = 15,
                minDesktopWidth = collapseMenuWidth;
            //Handles Section Ender height. 
            if (window.innerWidth >= minDesktopWidth) {
                $utilityNav.css("left", -($utilityNav.closest(".navbar-subsite").width() - offsetMargin)  + "px");
            } else {
                $utilityNav.css("left", "0");                
            }
        }
        utilityNavPosition();
        $(w).resize(utilityNavPosition);

        //Z-index fix for utility nav
        $('.navbar-subsite #navbar-hamburger-menu.dropdown').on('show.bs.dropdown', function (e) {
            $(this).parents(".navbar-subsite").toggleClass("higher-zindex");
        }).on('hide.bs.dropdown', function (e) {
            $(this).parents(".navbar-subsite").toggleClass("higher-zindex");
        });

        //Close all open subsite menus when touching off the menu
        $(document.body).on("touchstart", function (e) {
            if (window.innerWidth >= collapseMenuWidth) {
                $(".navbar-subsite .primary .dropdown.open").each(function () {
                    $(this).removeClass("open");
                });
            } 
            return;
        });
        //Handle keyboard, touch thru subsite nav
        $(".navbar-subsite .dropdown.level-1, .navbar-subsite .dropdown.level-2 a.dropdown-toggle").on("touchstart focus keydown mouseenter", function (e) {
            var keyCode = e.keyCode || e.which,
                eventType = e.type,
                $target = $(e.target);       
            if (window.innerWidth >= collapseMenuWidth) {
                function open($elem) {
                    if ($elem.hasClass(".dropdown")) {
                        $elem.addClass("open");
                    } else {
                        $elem.closest(".dropdown").addClass("open");
                    }
                }
                function close($elem) {
                    if ($elem.hasClass("dropdown")) {
                        $elem.removeClass("open");
                    } else {
                        $elem.closest(".dropdown").removeClass("open");
                    }
                }
                switch (eventType) {
                    case "touchstart":
                        if ($target.hasClass("level-1") || $target.closest(".dropdown").hasClass("level-1")) {
                            //Handle touch on top level
                            e.preventDefault();
                            e.stopPropagation();
                            if ($target.hasClass("open") || $target.closest(".dropdown").hasClass("open")) {
                                //If menu is open than navigate to the link
                                var link = "";
                                if ($target.is("a")) {
                                    link = $target.focus().attr("href");
                                } else if ($target.hasClass("link-text")) {
                                    link = $target.closest("a").focus().attr("href");
                                } else {
                                    link = $target.find("a").first().focus().attr("href");
                                }
                                window.location.href = link;
                            } else {
                                //If menu is not close all others and open
                                $(".navbar-subsite .primary .dropdown.open").each(function () {
                                    $(this).removeClass("open");
                                });
                                open($target);
                            }
                        } 
                        break;
                    case "focus":
                        open($target);
                        break;
                    case "keydown":
                        if (keyCode === 27 || (e.shiftKey && keyCode === 9 && $target.hasClass("dropdown-toggle"))) {
                            close($target);
                        }
                        break;
                    case "mouseenter":
                        $(".navbar-subsite .primary .dropdown.open").each(function() {
                            $(this).removeClass("open");
                        });
                        break;
                    default:
                        break;
                }
            }
            return;
        });
        $('.navbar-subsite .dropdown.level-2 .level-3 a').on("keydown", function (e) {
            var keyCode = e.keyCode || e.which,
                $target = $(e.target);
            if (!e.shiftKey && keyCode === 9 && $target.closest(".level-3").is(":last-child")) {
                $target.closest(".dropdown").removeClass("open");
            }
        });

        //Utility Nav tabbing behaviors
        $('.nav .hamburger.dropdown, #navbar-hamburger-menu .close, #navbar-hamburger-menu .small-nav a').on("keydown", function (e) {
            var smallNavLastAnchor = ".small-nav:last-child .menu-hover:last-child > a";
            var smallNavFirstAnchor = ".small-nav:first-child .menu-hover:first-child > a";
            var hamburgerButton = ".hamburger.dropdown button.dropdown-toggle";
            var $navbarDefaultSubsite = $(".navbar-default-subsite");
            var keyCode = e.keyCode || e.which;
            var $target = $(e.target);
            var dataTarget = $target.data("target");
            var $parent  = "";

            if ($target.hasClass("close") || keyCode === 27) {
                 $parent = $target.closest(".dropdown-menu");
                if (keyCode === 13 || keyCode === 27) {
                    //Handle enter press of close - close menu and focus on parent
                    $navbarDefaultSubsite.find(hamburgerButton).dropdown("toggle").focus();
                } else if (e.shiftKey && keyCode === 9) {
                    //Handle shift tab - focus on last anchor in menu   
                    $parent.find(smallNavLastAnchor).focus();
                } else if (keyCode === 9) {
                    //Handle tab on close - focus on arrow-link
                    return;
                }
                e.preventDefault();
            } else {
                if (keyCode === 13) {
                    //Handle enter of parent menu - open and focu
                    $target.dropdown("toggle");
                    $(dataTarget).find(smallNavFirstAnchor).focus();
                    e.preventDefault();                
                } else if (e.shiftKey && keyCode === 9 && $target.is(smallNavFirstAnchor)) {
                    //handle shift + tab on first anchor
                    $navbarDefaultSubsite.find(hamburgerButton).focus();
                    e.preventDefault();
                } else if (keyCode === 9 && $target.attr("aria-expanded") == "true") {
                    //Handle hamburger tab with menu open 
                    $target.dropdown("toggle");
                } else {
                    //handle tab of last utility nav item to close
                    if ($target.is(smallNavLastAnchor) && keyCode === 9) {
                        //$navbarHamburgerMenu.find(".utility-nav-top .close").focus();
                        $navbarDefaultSubsite.find(hamburgerButton).focus();
                        e.preventDefault();
                    }

                }
            }
        });

        //###### Begin Mobile Behaviors ######
        // ADD SLIDEDOWN ANIMATION TO DROPDOWN //
        $('.navbar-subsite .dropdown-menu.navbar-mobile').on('show.bs.dropdown', function (e) {
            $('.hamburger').addClass("open");
            $(this).first().stop(true, true).slideDown(200, function () {
                $(this).find('.mobile-nav-backdrop').height($('body').innerHeight() - $(this).find('.mobile-nav-container').innerHeight());
            });
        });
        $('.navbar-subsite .navbar-collapse.navbar-mobile').on('show.bs.collapse', function (e) {
            $('.hamburger').addClass("open");
            $(this).stop(true, true).slideDown(600, function () {
                $(this).find('.mobile-nav-backdrop').height($('body').innerHeight() - $(this).find('.mobile-nav-container').innerHeight());
            });
        });
        // ADD SLIDEUP ANIMATION TO DROPDOWN //
        $('.navbar-subsite .dropdown-menu.navbar-mobile').on('hide.bs.dropdown', function (e) {
            $('.hamburger').removeClass("open");
            $(this).first().stop(true, true).slideUp(200);
        });
        $('.navbar-subsite .navbar-collapse.navbar-mobile').on('hide.bs.collapse', function (e) {
            $('.hamburger').removeClass("open");
            $(this).stop(true, true).slideUp(400);
        });
        //Mobile slide-in offcanvas toggle
        $('[data-toggle=offcanvas], [data-toggle=offcanvas-subsite]').on("click", function (e) {
            var windowWidth = window.innerWidth
            if (window.innerWidth < collapseMenuWidth) {
                if ($(e.target).hasClass("offcanvas") || $(e.target).hasClass("offcanvas-link") || $(e.target).parent().parent().hasClass("offcanvas") || $(e.target).parent().hasClass("offcanvas-link")) {
                    e.stopPropagation();
                    e.preventDefault();
                    //set height of secondary nav to be the same as primary
                    $(this).parent().find('.sidebar-offcanvas .sub-menu').css("min-height", $('.mobile-nav-container').height() + "px");
                    //slide secondary into place
                    $(this).parent().find('.row-offcanvas').first().toggleClass('active').animate({ "right": "100%" }, 100);
                    //$(this).show("slide", { direction: "left" }, 1000);
                }
            }
        });
        $('[data-toggle=offcanvas-hide]').on("click", function (e) {
            if (window.innerWidth < collapseMenuWidth) {
                e.stopPropagation();
                e.preventDefault();
                var $rowOffCanvas = $(this).closest('.row-offcanvas');
                //slide secondary nav offscreen
                $rowOffCanvas.animate({ "right": "-15px" }, 100, function () {
                    //script is not waiting for css animation, so forcing wait
                    setTimeout(function () {
                        $rowOffCanvas.toggleClass('active');
                    }, 250);
                });
            }
        });
        $('.close-offcanvas').on("click", function (e) {
            if (window.innerWidth < collapseMenuWidth) {
                e.stopPropagation();
                e.preventDefault();
                $(this).closest('.row-offcanvas').animate({ "right": "-15px" }).toggleClass('active');
                $(this).closest('.dropdown-menu').first().stop(true, true).slideUp();
            }
        });        
        //###### End Mobile Behaviors ######

        //Fix navbar height when navbar-subsite items wrap
        function navbarHeightFix() {
            var $navbarSubsite = $(".navbar-subsite");
            if (window.innerWidth >= collapseMenuWidth) {
                $navbarSubsite.css("min-height", $navbarSubsite.find(".container-fluid > .navbar-collapse").height() + "px");
            } else {
                $navbarSubsite.css("min-height", "");
            }
        } 
        
        //After load is complete
        $(window).bind('load', function() {
            var zoom = document.documentElement.clientWidth / window.innerWidth;
            navZoom(zoom, document.documentElement.clientWidth);
            navbarHeightFix();
        });
        $(window).resize(function () {
            var zoom = document.documentElement.clientWidth / window.innerWidth;
            navZoom(zoom, document.documentElement.clientWidth);
            navbarHeightFix();
        });

        function navZoom(zoom, width) {
            var $navBarFixedTop = $('.navbar-fixed-top');
            if (zoom > 1) {
                $navBarFixedTop.addClass('navbar-zoomed').css("width", width + "px");
            }
            else {
                $navBarFixedTop.removeClass('navbar-zoomed').css("width", "auto");
            }
        }
        
    });
})(window, document, jQuery);